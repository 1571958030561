<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ Object.entries(this.data).length === 0 ? "Nuevo " : "Editar " }} {{ $t(entity).toLowerCase() }}</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <vs-input
          label="Código"
          v-model="code"
          class="mt-5 w-full"
          name="code"
          v-validate="'required|alpha_num'"
        />
        <span class="text-danger text-sm" v-show="errors.has('code')">
          {{
          errors.first("code")
          }}
        </span>

        <vs-input
          label="Alias"
          v-model="alias"
          class="mt-5 w-full"
          name="alias"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('alias')">
          {{
          errors.first("alias")
          }}
        </span>

        <vs-input label="Nombre" v-model="name" class="mt-5 w-full" />

        <vs-input label="Dirección" v-model="address" class="mt-5 w-full" />
        <vs-input label="Dirección 2" v-model="address2" class="mt-5 w-full" />
        <vs-input label="Ciudad" v-model="city" class="mt-5 w-full" />
        <vs-input label="CP" v-model="zip" class="mt-5 w-full" />

        <div class="vx-col w-full mb-2 mt-5">
          <label class="vs-input--label">Provincia</label>
          <v-select
            label="alias"
            :options="statesOrdered"
            :clearable="true"
            :reduce="option => ({ id: option.id, alias: option.alias })"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="state"
            class="mb-4 md:mb-0"
            name="Provincia"
            v-validate="'required'"
            :filterBy="filterByState"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('Provincia')"
          >{{ errors.first("Provincia") }}</span>
        </div>
        <div class="vx-col w-full mb-2 mt-5">
          <label class="vs-input--label">País</label>
          <v-select
            label="alias"
            :options="countries"
            :clearable="true"
            :reduce="option => ({ id: option.id, alias: option.alias })"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="country"
            class="mb-4 md:mb-0"
            name="País"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('País')">{{ errors.first("País") }}</span>
        </div>

        <vs-input
          label="Email"
          v-model="email"
          class="mt-5 w-full"
          name="email"
          v-validate="'email'"
        />
        <span class="text-danger text-sm mb-5" v-show="errors.has('email')">
          {{
          errors.first("email")
          }}
        </span>

        <vs-input
          label="Móvil"
          v-model="mobile"
          class="mt-5 w-full"
          name="mobile"
          v-validate="'digits:9'"
        />
        <span class="text-danger text-sm mb-5" v-show="errors.has('mobile')">
          {{
          errors.first("mobile")
          }}
        </span>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import { db } from "@/firebase/firebaseConfig";
import firebase from "firebase/app";

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    entity: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    },
    customer: {
      type: Object,
      required: true
    }
  },
  watch: {
    isSidebarActive(val) {
      console.log(val, Object.entries(this.data).length);
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        console.log("data", this.data);

        this.code = this.data.code;
        this.alias = this.data.alias;
        this.name = this.data.name;

        //this.initValues();
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    },
    country(obj) {
      if (obj) {
        this.$bind(
          "states",
          db.collection("states").where("country.id", "==", obj.id)
        );
      } else {
        this.$unbind("states");
        this.state = null;
      }
    }
  },
  data() {
    return {
      id: null,
      code: null,
      alias: null,
      name: null,
      address: null,
      address2: null,
      city: null,
      zip: null,
      state: null,
      country: null,
      email: null,
      mobile: null,

      states: [],
      countries: [],

      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  computed: {
    statesOrdered() {
      let array = this.states;
      array.sort((a, b) => {
        return a.alias.localeCompare(b.alias);
      });
      return array;
    },
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
          // this.$validator.reset()
          // this.initValues()
        }
      }
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect
  },
  firestore: {
    //states: db.collection("states").orderBy("alias")
    countries: db.collection("countries").orderBy("alias")
  },
  methods: {
    filterByState(option, label, search) {
      const removeDiacritics = str => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };
      return (
        removeDiacritics(label)
          .toLowerCase()
          .indexOf(search.toLowerCase()) > -1
      );
    },
    initValues() {
      if (this.data.id) return;
      this.code = null;
      this.alias = null;
      this.name = null;
      this.address = null;
      this.address2 = null;
      this.city = null;
      this.zip = null;
      this.state = null;
      //this.country = null;
      this.email = null;
      this.mobile = null;
    },
    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
          db.collection("establishments")
            .where("code", "==", this.code)
            .where("customer.id", "==", this.customer.id)
            .where("deleted", "==", false)
            .get()
            .then(snapshot => {
              if (snapshot.docs.length > 0) {
                this.errors.add({
                  field: "code",
                  msg: "El código de establecimiento ya existe."
                });
              } else {
                db.collection("establishments")
                  .add({
                    code: this.code,
                    alias: this.alias,
                    name: this.name,
                    address: this.address,
                    address2: this.address2,
                    city: this.city,
                    zip: this.zip,
                    state: this.state,
                    country: this.country,
                    customer: this.customer,
                    createdAt: firebase.firestore.Timestamp.now(),
                    createdBy: {
                      id: this.$store.state.AppActiveUser.uid,
                      email: this.$store.state.AppActiveUser.email
                    },
                    deleted: false
                  })
                  .then(docRef => {
                    this.$vs.notify({
                      title: "Documento creado" + docRef,
                      color: "success"
                    });
                  })
                  .catch(function(error) {
                    console.error("Error adding document: ", error);
                  });
                this.$emit("closeSidebar");
                this.initValues();
              }
            })
            .catch(function(error) {
              console.log("Error getting documents: ", error);
            });
        }
      });
    }
  },
  created() {
    db.collection("countries")
      .get()
      .then(doc => {
        console.log(doc);
        if (doc.docs.length === 1) {
          this.country = {
            id: doc.docs[0].id,
            alias: doc.docs[0].data().alias
          };
        }
      });
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
